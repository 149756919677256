<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
                <h2 style="font-size: 40px; font-weight: 700; line-height: 48px; " class="mb-0 mt-5 d-flex align-items-center">Administrator List <i @click="modals.help=!modals.help" class="fa fa-question-circle" style="cursor: pointer; margin-left: 20px;opacity: 0.8;font-size: 25px;"></i></h2>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
                <p>Add, edit or remove Administrators here. Administrator can login and use your system to manage Client accounts.</p>
            </div>
            <!-- <div class="col-sm-12 col-md-12 col-lg-12 pt-4">
               <div style="display:inline-block">
                <a href="#" @click="modals.helpguide = true" style="display:inline-flex"><i class="far fa-play-circle" style="font-size:21px;padding-right:5px"></i> <span>here for more information.</span></a>
               </div>
            </div> -->
        </div>
        <div class="pt-3 pb-3">&nbsp;</div>

        <div class="row">
             <div class="col-sm-12 col-md-12 col-lg-12">
                 <card>

                    <div class="row">
                        <div class="col-12">
                            <div id="txtSearchBox" class="pull-left" >                
                                <base-input>
                                    <el-input
                                        type="search"
                                        class="search-input input__administrator__list"
                                        clearable
                                        prefix-icon="el-icon-search"
                                        placeholder="Search Administrator"
                                        v-model="searchQuery"
                                        aria-controls="datatables"
                                        @keyup.native.enter="searchKeyWord"
                                        @clear="searchKeyWord"
                                    >
                                    </el-input>
                                </base-input>
                            </div>
                            <div class="container__filter__administrator__list">  
                                <base-button size="sm" class="button__add__administrator__list" style="height:40px;" v-if="this.$global.settingMenuShow_create" @click="AddEditAdmin('')">
                                    <i class="fas fa-plus-circle"></i> Add Administrator
                                </base-button>
                            </div>

                        </div>
                    </div>

                    <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(ProcessAddEditAdmin(''))">
                    <div id="showAddEditAdmin" class="row collapse">
                         <div class="col-sm-12 col-md-12 col-lg-12 pt-2 pb-2">&nbsp;</div> 

                         <!--<div class="col-sm-3 col-md-3 col-lg-3 form-group has-label">
                            <base-input
                                label="Company Name"
                                type="text"
                                placeholder="Input Company Name"
                                addon-left-icon="fas fa-building"
                                v-model="ClientCompanyName"
                                >
                            </base-input>
                        </div>-->
                         <div class="col-sm-6 col-md-6 col-lg-6">
                            <ValidationProvider
                            name="Full Name"
                            rules="required"
                            v-slot="{ passed, failed, errors }"
                            >   
                            <base-input
                                label="Full Name"
                                type="text"
                                placeholder="Input Admin Name"
                                addon-left-icon="far fa-id-badge"
                                v-model="ClientFullName"
                                :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                >
                            </base-input>
                            </ValidationProvider>
                          </div>
                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <ValidationProvider
                            name="email"
                            rules="required|email"
                            v-slot="{ passed, failed, errors }"
                            >    
                            <base-input
                                label="Email"
                                type="email"
                                placeholder="Input Admin Email"
                                addon-left-icon="fas fa-envelope"
                                v-model="ClientEmail"
                                :lowercase="true"
                                :error="errors[0]"
                                :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                >
                            </base-input>
                            </ValidationProvider>
                          </div>

                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <base-input
                                label="Password"
                                type="password"
                                placeholder="Input Password"
                                addon-left-icon="fas fa-key"
                                v-model="ClientPass[0]"
                                >
                            </base-input>
                          </div>

                           <div class="col-sm-6 col-md-6 col-lg-6">
                            <!-- <base-input
                                label="Phone Number"
                                type="text"
                                placeholder="Input Admin Phone"
                                addon-left-icon="fas fa-phone-alt"
                                v-model="ClientPhone"
                                class="phonenum"
                                >
                            </base-input> -->
                            <label>Phone Number</label>
                            <VuePhoneNumberInput :defaultCountryCode="clientPhoneNumber.countryCode" v-model="clientPhoneNumber.number" @update="onPhoneUpdate" />
                          </div>
                          <!-- FOR ROLE-->
                          
                            <div class="col-sm-3 col-md-3 col-lg-3">
                                    <div class="form-group has-label pull-left">
                                        <label>Select Admin Role</label>
                                            <el-select
                                                class="select-primary"
                                                size="large"
                                                placeholder="Select Role"
                                                v-model="selects.roleSelected"
                                                @change="onRoleChange(0);"
                                            >
                                            
                                                <el-option
                                                    v-for="option in selects.roleType"
                                                    class="select-primary"
                                                    :value="option.id"
                                                    :label="option.role_name"
                                                    :key="option.id"
                                                >
                                                <i :class="option.role_icon"></i> {{ option.role_name }}
                                                </el-option>
                                            </el-select>
                                    </div>
                            </div>
                                <div class="col-sm-3 col-md-3 col-lg-3" style="padding:25px;">
                                    <base-checkbox  v-model="defaultAdmin" class="pull-left">set as default admin</base-checkbox>
                                </div>
                                <div class="col-sm-3 col-md-3 col-lg-3" style="padding:25px;">
                                    <base-checkbox  v-model="defaultCustCare" class="pull-left">set as customer care</base-checkbox>
                                </div>
                                <div class="col-sm-3 col-md-3 col-lg-3" style="padding:25px;">
                                    <base-checkbox  v-model="adminGetNotification" class="pull-left">set admin to receive failed billing notifications</base-checkbox>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" v-if="$global.rootcomp && userData.systemuser && (userData.permission_active == 'T' || userData.permission_active == null) && !userData.isViewMode">
                                    <hr class="divider-hr" style="opacity: 0.5;"/>
                                    <p style="font-size: 14px; opacity: 0.7; font-weight: 600;">Admin Permissions:</p>
                                    <div class="row">
                                        <div class="col-sm-3 col-md-3 col-lg-3" style="padding:15px;">
                                            <base-checkbox v-model="permissionActive" class="pull-left">set permissions</base-checkbox>
                                        </div>
        
                                        <div class="col-sm-3 col-md-3 col-lg-3" style="padding:15px;">
                                            <base-checkbox v-model="viewReportAnalytics" class="pull-left">view report</base-checkbox>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-3 col-md-3 col-lg-3 ml-3" hidden>
                                    <div class="form-group has-label pull-left">
                                        <label class="row">Two-factor Authentication</label>
                                        <div class="row">
                                        <base-checkbox 
                                        :checked="twoFactorAuth === 'email'" 
                                        @change="selectTfa('email')" style="padding-inline:4px;">Email</base-checkbox>

                                        <base-checkbox 
                                            :checked="twoFactorAuth === 'twilio'" 
                                            @change="selectTfa('twilio')" style="padding-inline:4px;">Twilio</base-checkbox>

                                        <base-checkbox 
                                            :checked="twoFactorAuth === 'whatsapp'" 
                                            @change="selectTfa('whatsapp')" style="padding-inline:4px;">WhatsApp</base-checkbox>
                                        </div>
                                    </div>
                                </div>
                            <!-- FOR ROLE-->
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <base-button native-type="submit" size="sm" class="pull-right" id="btnSave" style="height:40px" >
                                    Save
                                </base-button>
                                <base-button size="sm" class="pull-right mr-4" style="height:40px" @click="CancelAddEditAdmin('')">
                                    Cancel
                                </base-button>
                            </div>
                          
                          <div class="col-sm-12 col-md-12 col-lg-12 pt-4 pb-4">&nbsp;</div> 
                     </div>
                    </form>
                    </ValidationObserver>
                    

                     <div class="row">
                         <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap tab-table">
                            <el-table @expand-change="onExpandClick" :data="queriedData" @sort-change="sortdynamic" :row-class-name="tableRowClassName" ref="tableData" :default-sort="{prop: 'full_name', order: 'ascending'}">
                                    <template #empty>
                                        <i class="fas fa-spinner fa-pulse fa-2x d-block"></i>Loading data...
                                    </template>
                                    <!--<el-table-column  min-width="20%" label="Company Name">
                                        <div slot-scope="props">
                                              {{props.row.company_name}}
                                        </div>
                                    </el-table-column>-->
                                    <el-table-column  min-width="160" prop="full_name" sortable="custom" label="Full Name">
                                        <div slot-scope="props">
                                              {{props.row.name}}
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="200" prop="email" sortable="custom"  label="E-mail">
                                        <div slot-scope="props">
                                              {{props.row.email}}
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="120" prop="phone" sortable="custom" label="Phone">
                                        <div slot-scope="props">
                                              {{props.row.phonenum}}
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="150"  label="Permission">
                                        <div slot-scope="props" v-html="SetRoleUser(props.row.role_id)">
                                        </div>
                                    </el-table-column>
                                    <el-table-column  min-width="120"  prop="created_at" sortable="custom" align="center" label="Created">
                                        <div slot-scope="props">
                                              {{props.row.created_at}}
                                        </div>
                                    </el-table-column>
                                    <el-table-column min-width="120" align="center" label="Actions">
                                            <div slot-scope="props">
                                                <base-button
                                                    @click.native="rowClicked(props.row)"
                                                    class="edit btn-link"
                                                    type="neutral"
                                                    size="sm"
                                                    icon
                                                    >
                                                   <i class="fa-solid fa-pen-to-square" style="color:gray"></i>
                                                </base-button>
                                                <!-- <base-button
                                                   
                                                    class="remove btn-link"
                                                    type="danger"
                                                    size="sm"
                                                    icon
                                                    >
                                                </base-button> -->
                                                <i  v-if="$global.settingMenuShow_delete && userData.id != props.row.id"
                                                    @click="handleDelete(props.$index, props.row)" class="fa-solid fa-circle-x"></i>

                                            </div>
                                    </el-table-column>

                                    <el-table-column  min-width="100%" type="expand">
                                            <!-- START EDIT AREA -->
                                            <template slot-scope="scope">
                                                    <ValidationObserver v-slot="{ handleSubmit }">
                                                    <form :id="'frmadmin' + scope.row" @submit.prevent="handleSubmit(ProcessAddEditAdmin(scope.row))">
                                                    <div class="row">
                                                        <!--<div class="col-sm-3 col-md-3 col-lg-3 form-group has-label">
                                                                <base-input
                                                                    label="Company Name"
                                                                    type="text"
                                                                    placeholder="Input Company Name"
                                                                    addon-left-icon="fas fa-building"
                                                                    v-model="scope.row.company_name"
                                                                    >
                                                                </base-input>  
                                                        </div>-->
                                                        <div class="col-sm-6 col-md-6 col-lg-6">
                                                                <ValidationProvider
                                                                name="Full Name"
                                                                rules="required"
                                                                v-slot="{ passed, failed, errors }"
                                                                > 
                                                                <base-input
                                                                    label="Full Name"
                                                                    type="text"
                                                                    placeholder="Input Admin Name"
                                                                    addon-left-icon="far fa-id-badge"
                                                                    v-model="scope.row.name"
                                                                    :error="errors[0]"
                                                                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                                                    >
                                                                </base-input>
                                                                </ValidationProvider>
                                                        </div>
                                                        <div class="col-sm-6 col-md-6 col-lg-6">
                                                                <ValidationProvider
                                                                name="email"
                                                                rules="required|email"
                                                                v-slot="{ passed, failed, errors }"
                                                                >
                                                                <base-input
                                                                    label="Email"
                                                                    type="email"
                                                                    placeholder="Input Admin Email"
                                                                    addon-left-icon="fas fa-envelope"
                                                                    v-model="scope.row.email"
                                                                    :lowercase="true"
                                                                    :error="errors[0]"
                                                                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                                                                    >
                                                                </base-input>
                                                                </ValidationProvider>
                                                        </div>

                                                        <div class="col-sm-6 col-md-6 col-lg-6">
                                                            <base-input
                                                                label="Password"
                                                                type="password"
                                                                placeholder="Input Password"
                                                                addon-left-icon="fas fa-key"
                                                                v-model="ClientPass[scope.row.id]"
                                                                >
                                                            </base-input>
                                                        </div>

                                                        <div class="col-sm-6 col-md-6 col-lg-6">
                                                                <!-- <base-input
                                                                    label="Phone Number"
                                                                    type="text"
                                                                    placeholder="Input Admin Phone"
                                                                    addon-left-icon="fas fa-phone-alt"
                                                                    v-model="scope.row.phonenum"
                                                                    class="phonenum"
                                                                    >
                                                                </base-input> -->
                                                                <label>Phone Number</label>
                                                                <VuePhoneNumberInput :defaultCountryCode="clientRowPhoneNumber.countryCode" v-model="clientRowPhoneNumber.number" @update="onRowPhoneUpdate" />
                                                        </div>

                                                        <!-- FOR ROLE-->
                                                        <div class="col-sm-3 col-md-3 col-lg-3">
                                                                <div class="form-group has-label pull-left">
                                                                    <label>Select Admin Role</label>
                                                                        <el-select
                                                                            class="select-primary"
                                                                            size="large"
                                                                            placeholder="Select Role"
                                                                            v-model="selects.roleSelected"
                                                                            @change="onRoleChange(scope.row.id);"
                                                                        >
                                                                        
                                                                            <el-option
                                                                                v-for="option in selects.roleType"
                                                                                class="select-primary"
                                                                                :value="option.id"
                                                                                :label="option.role_name"
                                                                                :key="option.id"
                                                                            >
                                                                            <i :class="option.role_icon"></i> {{ option.role_name }}
                                                                            </el-option>
                                                                        </el-select>
                                                                </div>
                                                        </div>
                                                        <!-- FOR ROLE-->
                                                        <div class="col-sm-3 col-md-3 col-lg-3" style="padding:25px;">
                                                             <base-checkbox :class="cssCheckBoxRole()" @change="setDefaultAdmin(scope.row)" value="T" :checked="scope.row.defaultadmin == 'T' ? true : false"  inline> set as default admin</base-checkbox>
                                                        </div>
                                                        <div class="col-sm-3 col-md-3 col-lg-3" style="padding:25px;">
                                                            <base-checkbox :class="cssCheckBoxRole()" @change="setDefaultCustCare(scope.row)" value="T" :checked="scope.row.customercare == 'T' ? true : false"  inline> set as customer care</base-checkbox>
                                                        </div>
                                                        <div class="col-sm-3 col-md-3 col-lg-3" style="padding:25px;">
                                                            <base-checkbox :class="cssCheckBoxRole()" @change="setAdminGetNotification(scope.row)" value="T" :checked="scope.row.admin_get_notification == 'T' ? true : false"  inline> set admin to receive failed billing notifications</base-checkbox>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-12" v-if="$global.rootcomp && userData.systemuser && userData.id != scope.row.id && (userData.permission_active == 'T' || userData.permission_active == null) && !userData.isViewMode">
                                                            <hr class="divider-hr" style="opacity: 0.5;"/>
                                                            <p style="font-size: 14px; opacity: 0.7; font-weight: 600;">Admin Permissions:</p>
                                                            <div class="row">
                                                                <div class="col-sm-3 col-md-3 col-lg-3" style="padding:15px;">
                                                                    <base-checkbox :class="cssCheckBoxRole()" inline v-model="rowPermissionActive">set permission</base-checkbox>
                                                                </div>
                                                                <div class="col-sm-3 col-md-3 col-lg-3" style="padding:15px;">
                                                                    <base-checkbox :class="cssCheckBoxRole()" inline v-model="rowViewReportAnalytics">set view report</base-checkbox>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-9 col-md-9 col-lg-9">
                                                            &nbsp;
                                                        </div>

                                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                                            <base-button native-type="submit" v-if="$global.settingMenuShow_update" size="sm" class="pull-right" :id="'btnSave' + scope.row.id" style="height:40px">
                                                                Save
                                                            </base-button>
                                                            <base-button size="sm" class="pull-right mr-4" style="height:40px" @click="CancelAddEditAdmin(scope.row)">
                                                                Cancel
                                                            </base-button>
                                                            <base-button size="sm" class="pull-right mr-4" :id="'btnResend' + scope.row.id" style="height:40px" @click="ResendInvitation(scope.row)">
                                                                Resend Invitation
                                                            </base-button>
                                                        </div>
                                                    </div>
                                                    </form>
                                                </ValidationObserver>
                                            </template>

                                            <!-- START EDIT AREA -->
                                    </el-table-column>

                            </el-table>
                         </div>
                     </div>
                    
                    <template slot="footer">
                        <div class="tab-footer pull-right">
                                    <div class="pt-3">
                                        <p class="card-category">
                                            Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                                        </p>
                                    </div>
                                        <base-pagination
                                        class="pagination-no-border pt-4"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total="pagination.total"
                                        @input="changePage"
                                        >
                                        </base-pagination>
                                </div>
                    </template>

                 </card>
             </div>    
        </div>
                            <!-- Modal Video Guide -->
                            <modal :show.sync="modals.helpguide" headerClasses="justify-content-center" modalContentClasses="customhead">
                              <h4 slot="header" class="title title-up">What is on Dashboard Menu?</h4>
                              <p class="text-center">
                                Watch the video below if you still have question please <a href="#">Contact Us</a>
                              </p>
                              <div>
                                <!--<iframe width="970" height="415" src="https://www.youtube.com/embed/SCSDyqRP7cY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                              </div>
                              <template slot="footer">
                                <div class="container text-center pb-4">
                                  <base-button  @click.native="modals.helpguide = false">Ok I already understand</base-button>
                                </div>
                              </template>
                            </modal>
                            <!-- Modal Video Guide -->

        <modal :show.sync="modals.help" headerClasses="">
            <div slot="header" class="d-flex flex-column gap-4"><h4  class="title title-up">{{activeHelpItem.title}}</h4> 
              
             </div>
            <div v-if="modals.help" v-html="activeHelpItem.embedVideoCode"></div>
            <p class="text-dark mt-4" style="font-size: 14px;" v-html="activeHelpItem.desc"></p>
        </modal>

    </div>
</template>
<script>
import { extend } from "vee-validate";
import { required, email} from "vee-validate/dist/rules";
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Collapse, CollapseItem,Modal } from 'src/components';
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import VuePhoneNumberInput from '@/components/VuePhoneNumberInput';
import { emailPattern } from '../../../../util/validation'

extend("email", email);
extend("required", required);

export default {
    components: {
        Modal,
        Collapse,
        CollapseItem,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Option.name]: Option,
        [Select.name]: Select,
        BasePagination,
        VuePhoneNumberInput
    },
    data() {
        return {
            adminGetNotification: false,
            clientPhoneNumber:{
                number:'',
                countryCode:'US',
                countryCallingCode:'+1'
            },
            clientRowPhoneNumber:{
                number:'',
                countryCode:'US',
                countryCallingCode:'+1'
            },
             tableDataOri:[],
             tableData: [],
             fuseSearch: null,
             searchedData: [],
             searchQuery: '',
             pagination: {
                perPage: 10,
                currentPage: 1,
                //perPageOptions: [5, 10, 25, 50],
                total: 0,
                from: 0,
                to: 0,
            },
            modals: {
                helpguide: false,
                help:false,
            },
            companyID:'',
            activeHelpItem:  {   title:'Administrators',
                embedVideoCode:'<iframe src="https://drive.google.com/file/d/1dmNvmcyeztHUrKtcLrsAyKSNmCTelJnt/preview" width="100%" height="480" allow="autoplay"></iframe>',
                desc:'In this section you can add and edit your system administrators. We do not charge for administrators so you may add as many as you need for your business.<br>Make sure that every administrator and client has a unique email address, as email addresses are used for access permissions.<br>If you set someone as a default admin, they will receive new campaign creation notices as well as have this email address added as an editor by default to all the Google sheets that are created. You may have unlimited default admins.<br>The customer care selection is singular. You can only have one customer care admin in the system as this is used as a token in the email templates.<br>The third checkbox is if an admin wants to receive notifications of failed billing.<br>An admin can be set as one two or three check boxes. They are not exclusive.'
            },

            ClientCompanyName: '',
            ClientFullName: '',
            ClientEmail: '',
            ClientPhone: '',
            ClientPass:[],
            ClientRole:[],
            ClientRoleDefault:'',

            selects: {
                roleSelected: '',
                roleType: [],
            },

            userData: '',
            defaultAdmin: false,
            defaultCustCare: false,
            ClientPermissionIcon: [
                {
                    IconRole: 'fa-solid fa-key',
                    RoleName: 'Super Admin',
                },
                {
                    IconRole: 'fa-solid fa-eye',
                    RoleName: 'View Only',
                },
                {
                    IconRole: 'fa-solid fa-book',
                    RoleName: 'Reporting',
                }
            ],   
            currSortBy: '',
            currOrderBy: '',
            twoFactorAuth: '', 
            expandedRow: null,
            isProcessingExpandRow: false,
            permissionActive: false,
            rowPermissionActive: false,
            viewReportAnalytics: false,
            rowViewReportAnalytics: false,
        }
    },

    computed: {
        /***
         * Returns a page from the searched data or the whole data. Search is performed in the watch section below
         */
        queriedData() {
        let result = this.tableData;
            if (this.searchedData.length > 0) {
                result = this.searchedData;
            }
            //return result.slice(this.from, this.to);
            return result;
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.searchedData.length > 0
                ? this.searchedData.length
                : this.tableData.length;
        }
    },

    methods: {
        changePage(event){
            //console.log(this.pagination,event)
            this.GetAdminList(this.currSortBy,this.currOrderBy)
        },
        onPhoneUpdate(payload){
           this.clientPhoneNumber.countryCode = payload.countryCode
           this.clientPhoneNumber.countryCallingCode = payload.countryCallingCode
        },
        onRowPhoneUpdate(payload){
            this.clientRowPhoneNumber.countryCode = payload.countryCode
            this.clientRowPhoneNumber.countryCallingCode = payload.countryCallingCode
        },
        resetPhone(){
            this.clientPhoneNumber={
                number:'',
                countryCode:'US',
                countryCallingCode:'+1'
            }
        },
        setDefaultAdmin(row) {
            //console.log(row.defaultadmin);
            if (row.defaultadmin == 'T') {
                row.defaultadmin = 'F';
            }else{
                row.defaultadmin = 'T';
            }
        },
        setDefaultCustCare(row) {
            if (row.customercare == 'T') {
                row.customercare = 'F';
            }else{
                row.customercare = 'T';
            }
        },
        setAdminGetNotification(row) {
            //console.log(row.admin_get_notification);
            if (row.admin_get_notification == 'T') {
                row.admin_get_notification = 'F';
            }else{
                row.admin_get_notification = 'T';
            }
        },
        sortcolumn: function(a,b) {
            return a.value - b.value;
        },
        sortdate: function(a,b) {
            return new Date(b.created_at) - new Date(a.created_at);
        },
         sortnumber: function(a,b) {
             return a - b;
        },
        SetRoleUser(roleid) {
            //console.log(roleid);
            if (roleid == '' || roleid == '0') {
                return "<i class='fas fa-exclamation-triangle'></i>";
            }else{
                for(let i=0;i<this.selects.roleType.length;i++) {
                    //console.log(this.selects.roleType[i]);
                    if(this.selects.roleType[i]['id'] == roleid) {
                        return  '<i class="' + this.selects.roleType[i]['role_icon'] + '" style="padding-right:10px"></i>' + this.selects.roleType[i]['role_name'];
                    }
                }
            }
        },
        onRoleChange(row) {
            this.ClientRole[row] = this.selects.roleSelected;
        },
        rowClicked(row) {
            if(row.user_permissions){
                const userPermissions = JSON.parse(row.user_permissions)
                this.rowPermissionActive = row.permission_active === 'T' ? true : false;
                this.rowViewReportAnalytics = userPermissions.report_analytics;
            } else {
                this.rowPermissionActive = true;
                this.rowViewReportAnalytics = true;
            }

            if (this.isProcessingExpandRow) {
                return;
            }

            this.isProcessingExpandRow = true;

            if (this.expandedRow === row) {
                // Closing expand

                this.$refs.tableData.toggleRowExpansion(row, false);
                this.expandedRow = null;
            } else {
                // Closing all row expand 
                this.tableData.forEach(item => {
                    this.$refs.tableData.toggleRowExpansion(item, false);
                });

                // Exapand new row
                this.$refs.tableData.toggleRowExpansion(row, true);
                this.expandedRow = row;
            }

            this.isProcessingExpandRow = false;
            
            this.clientRowPhoneNumber.number = row.phonenum
            this.clientRowPhoneNumber.countryCode = row.phone_country_code
            this.clientRowPhoneNumber.countryCallingCode = row.phone_country_calling_code

            if(row.role_id == '' || row.role_id == '0') {
                this.selects.roleSelected = '';
            }else{
                this.selects.roleSelected = parseInt(row.role_id);
            }
        },
        tableRowClassName({row, rowIndex}) {
                row.index = rowIndex;
                return 'clickable-rows ClientRow' + rowIndex;
        },
        ClearAdminForm() {
            this.ClientCompanyName = '';
            this.ClientFullName = '';
            this.ClientEmail = '';
            this.ClientPhone = '';
            this.adminGetNotification = false;
            this.defaultAdmin = false;
            this.defaultCustCare = false;
            this.resetPhone()
        },
        AddEditAdmin(id) {
            this.resetPhone()
            $('#showAddEditAdmin' + id).collapse('show');
        },
        CancelAddEditAdmin(id) {
            if(id == '') {
              this.ClearAdminForm();
              this.ClientPass[0] = '';
              $('#showAddEditAdmin' + id).collapse('hide');
            }else{
                this.ClientPass[id.id] = '';
                this.$refs.tableData.toggleRowExpansion(id);
                this.GetAdminList(this.currSortBy,this.currOrderBy);
            }
            
        },
        ResendInvitation(id) {
            if(id.id != '') {
                $('#btnResend' + id.id).attr('disabled',true);
                $('#btnResend' + id.id).html('Sending...');   

                /** RESEND THE INVITATION */
                this.$store.dispatch('ResendInvitation', {
                    ClientID: id.id,
                }).then(response => {
                    //console.log(response[0]);
                    this.$refs.tableData.toggleRowExpansion(id);
                    $('#btnResend' + id.id).attr('disabled',false);
                    $('#btnResend' + id.id).html('Resend Invitation'); 

                    this.$notify({
                        type: 'success',
                        message: 'Invitation has been sent!',
                        icon: 'far fa-save'
                    });  

                },error => {
                    $('#btnResend' + id.id).attr('disabled',false);
                    $('#btnResend' + id.id).html('Resend Invitation'); 

                    this.$notify({
                        type: 'primary',
                        message: 'Sorry there is something wrong, pleast try again later',
                        icon: 'fas fa-bug'
                    }); 
                });
                /** RESEND THE INVITATION */
            }
        },
        
        selectTfa(type) {
        this.twoFactorAuth = this.twoFactorAuth === type ? '' : type;
        console.log(this.twoFactorAuth)
         },
        ProcessAddEditAdmin(id) {
            // di centahng true, jika tidak false
            // console.log(this.adminGetNotification);
            if(this.ClientFullName != '' && this.ClientEmail != '' && id == '') {
                /** PROCESS ADD / EDIT ORGANIZATION */
                if(id == '') {
                    var _defaultAdmin = "F";
                    var _defaultCustCare = "F";
                    var _adminGetNotification = "F";

                    if (this.defaultAdmin) {
                        _defaultAdmin = "T";
                    }

                    if (this.defaultCustCare) {
                        _defaultCustCare = "T";
                    }

                    if(this.adminGetNotification) {
                        _adminGetNotification = "T";
                    }

                    let permission_active = null
                    let user_permissions = null
                    if(this.$global.rootcomp && this.userData.systemuser && !this.userData.isViewMode){
                        // Permission only root emm
                        permission_active = this.permissionActive ? 'T' : 'F';

                        user_permissions = {
                            external_sf: this.viewReportAnalytics ? true : false,
                            report_analytics: this.viewReportAnalytics ? true : false,
                        };
                        // Permission only root emm
                    }

                    $('#btnSave' + id).attr('disabled',true);
                    $('#btnSave' + id).html('Processing...'); 

                    const regex = /^\s*$/;
                    if (regex.test(this.ClientFullName)) {
                        this.$notify({
                            type: 'danger',
                            message: 'Full Name invalid',
                            icon: 'fas fa-exclamation'
                        });
                        $('#btnSave' + id).attr('disabled',false);
                        $('#btnSave' + id).html('Save'); 
                        return; 
                    }

                    if (!emailPattern.test(this.ClientEmail)) {
                        this.$notify({
                            type: 'danger',
                            message: `Email invalid`,
                            icon: 'fas fa-exclamation-circle'
                        })

                        $('#btnSave' + id).attr('disabled',false);
                        $('#btnSave' + id).html('Save');
                        return false
                    }

                    /** CREATE CLIENT */
                    this.$store.dispatch('CreateClient', {
                        companyID: this.companyID,
                        idsys: this.$global.idsys,
                        userType:'user',
                        ClientCompanyName: '',
                        ClientFullName: this.ClientFullName,
                        ClientEmail: this.ClientEmail,
                        ClientPhone: this.clientPhoneNumber.number,
                        ClientPhoneCountryCode: this.clientPhoneNumber.countryCode,
                        ClientPhoneCountryCallingCode: this.clientPhoneNumber.countryCallingCode,
                        ClientPass: this.ClientPass[0],
                        ClientRole: this.selects.roleType[0]['id'],
                        defaultAdmin: _defaultAdmin,
                        customercare: _defaultCustCare,
                        adminGetNotification: _adminGetNotification,
                        twoFactorAuth: 'email',
                        permission_active: permission_active,
                        user_permissions: user_permissions
                    }).then(response => {
                        //console.log(response[0]);
                        if (response.result == 'success') {
                            this.tableData.push(response.data[0]);
                            this.initialSearchFuse();
                            this.ClearAdminForm();
                            this.ClientPass[0] = '';
                            this.ClientRole[0] = this.selects.roleType[0]['id'];
                            $('#showAddEditAdmin' + id).collapse('hide');
                            $('#btnSave' + id).attr('disabled',false);
                            $('#btnSave' + id).html('Save'); 
                            
                            this.$notify({
                                type: 'success',
                                message: 'Data has been added successfully',
                                icon: 'far fa-save'
                            });  

                            this.GetAdminList()
                        }else{
                            $('#btnSave' + id).attr('disabled',false);
                            $('#btnSave' + id).html('Save'); 

                            this.$notify({
                                type: 'primary',
                                message: response.message,
                                icon: 'fas fa-bug'
                            });
                        }
                    },error => {
                        $('#btnSave' + id).attr('disabled',false);
                        $('#btnSave' + id).html('Save'); 

                        this.$notify({
                            type: 'primary',
                            message: 'Server might be busy please try again later',
                            icon: 'fas fa-bug'
                        });
                    });
                    /** CREATE CLIENT */
                }
                /** PROCESS ADD / EDIT ORGANIZATION */
            }else{
                if ((id.name != '' && id.email != '') && (typeof id.name != 'undefined' && typeof id.email != 'undefined')) {
                    $('#btnSave' + id.id).attr('disabled',true);
                    $('#btnSave' + id.id).html('Processing...');

                    
                    
                    let permission_active = null
                    let user_permissions = null
                    if(this.$global.rootcomp && this.userData.systemuser && !this.userData.isViewMode){
                        // Permission only root emm
                        permission_active = this.rowPermissionActive ? 'T' : 'F';

                        user_permissions = {
                            external_sf: this.rowViewReportAnalytics ? true : false,
                            report_analytics: this.rowViewReportAnalytics ? true : false,
                        };
                        // Permission only root emm
                    }

                    const regex = /^\s*$/;
                    if (regex.test(id.name)) {
                        this.$notify({
                            type: 'danger',
                            message: 'Full Name invalid',
                            icon: 'fas fa-exclamation'
                        });
                        $('#btnSave' + id.id).attr('disabled',false);
                        $('#btnSave' + id.id).html('Save'); 
                        return; 
                    }

                    if (!emailPattern.test(id.email)) {
                        this.$notify({
                            type: 'danger',
                            message: `Email invalid`,
                            icon: 'fas fa-exclamation-circle'
                        })

                        $('#btnSave' + id.id).attr('disabled',false);
                        $('#btnSave' + id.id).html('Save');
                        return;
                    }

                    /** UPDATE CLIENT */
                    this.$store.dispatch('UpdateClient', {
                        companyID: id.company_id,
                        idsys: this.$global.idsys,
                        ClientID: id.id,
                        ClientFullName: id.name,
                        ClientEmail: id.email,
                        ClientPhone: this.clientRowPhoneNumber.number,
                        ClientPhoneCountryCode: this.clientRowPhoneNumber.countryCode,
                        ClientPhoneCountryCallingCode: this.clientRowPhoneNumber.countryCallingCode,
                        ClientPass: this.ClientPass[id.id],
                        ClientRole: (typeof(this.ClientRole[id.id]) == "undefined")?this.selects.roleType[0]['id']:this.ClientRole[id.id],
                        defaultAdmin: id.defaultadmin,
                        customercare: id.customercare,
                        action: 'administrator',
                        adminGetNotification: id.admin_get_notification,
                        permission_active: permission_active,
                        user_permissions: user_permissions
                    }).then(response => {
                        //console.log(response[0]);
                        for(let i=0;i<this.tableData.length;i++) {
                            if(this.tableData[i]['id'] == id.id) {
                                if (typeof(this.ClientRole[id.id]) !== 'undefined') {
                                    this.tableData[i]['role_id'] = this.ClientRole[id.id];
                                }
                            }
                        }

                        /** UPDATE USER LOGIN STORAGE DATA */
                        if (id.id == this.userData.id) {
                            this.userData.email = id.email;
                            this.userData.name = id.name;
                            this.userData.phonenum = id.phonenum;
                            this.userData.defaultadmin = id.defaultAdmin;

                            const updatedData = {
                                email: id.email,
                                name: id.name,
                                phonenum: id.phonenum,
                                defaultadmin: id.defaultAdmin,
                            }
                            
                            this.$store.dispatch('updateUserData', updatedData);
                        }
                        /** UPDATE USER LOGIN STORAGE DATA */

                        this.$refs.tableData.toggleRowExpansion(id);
                        $('#btnSave' + id.id).attr('disabled',false);
                        $('#btnSave' + id.id).html('Save'); 
                        this.$notify({
                            type: 'success',
                            message: 'Data has been updated successfully',
                            icon: 'far fa-save'
                        });
                        
                        this.GetAdminList()
                    },error => {
                        $('#btnSave' + id.id).attr('disabled',false);
                        $('#btnSave' + id.id).html('Save'); 

                        if(error.status === 422){
                            this.$notify({
                                type: 'primary',
                                message: error.data.message,
                                icon: 'fas fa-bug'
                            });

                            return;
                        }

                        this.$notify({
                            type: 'primary',
                            message: 'Server might be busy please try again later',
                            icon: 'fas fa-bug'
                        }); 
                    });
                    /** UPDATE CLIENT */
                }
            }
        },
        GetRoleList(id,typereq) {
            this.$store.dispatch('GetRoleList', {
                    companyID: this.companyID,
                    getType:typereq,
                    roleID:id,
                }).then(response => {
                    //console.log(response[0].id);
                    this.ClientRole[0] = parseInt(response[0].id);
                    this.selects.roleSelected = parseInt(response[0].id);
                    this.selects.roleType = response;
                },error => {
                    
                });
        },
        sortdynamic(column,prop,order) { 
            this.currSortBy = column.prop;
            this.currOrderBy = column.order;
            this.GetAdminList(column.prop,column.order);
        },
        searchKeyWord() {
            this.pagination.currentPage = 1;
            this.GetAdminList(this.currSortBy,this.currOrderBy);
        },
        GetAdminList(sortby,order) {
            var _sortby = '';
            var _order = '';
            var _searchkey = '';

            if (typeof(sortby) != 'undefined') {
                _sortby = sortby;
            }
            if (typeof(order) != 'undefined') {
                _order = order;
            }

            if (this.searchQuery != '') {
                _searchkey = this.searchQuery;
            }

            /** GET CLIENT LIST */
            this.tableData = [];
            $('.el-table__empty-text').html('<i class="fas fa-spinner fa-pulse fa-2x d-block"></i>Loading data...');
            this.$store.dispatch('GetClientList', {
                companyID: this.companyID,
                idsys: this.$global.idsys,
                userType:'user',
                sortby: _sortby,
                order: _order,
                searchkey: _searchkey,
                page:this.pagination.currentPage,
            }).then(response => {
                this.pagination.currentPage = response.current_page? response.current_page : 1
                this.pagination.total = response.total ?response.total : 0
                this.pagination.lastPage = response.last_page ? response.last_page : 2
                this.pagination.from = response.from ? response.from : 0
                this.pagination.to = response.to ? response.to : 2

                this.tableData = response.data
                this.initialSearchFuse()
                this.GetRoleList('','getrole');
                if (response.data.length == 0) {
                    $('.el-table__empty-text').html('No Data');
                }
            },error => {
                
            });
            /** GET CLIENT LIST */
        },

        initialSearchFuse() {
            // Fuse search initialization.
            // this.fuseSearch = new Fuse(this.tableData, {
            //     keys: ['name','email','phonenum','created_at'],
            //     threshold: 0.1
            // });
        },

        handleLike(index, row) {
            swal.fire({
                title: `You liked ${row.name}`,
                buttonsStyling: false,
                icon: 'success',
                customClass: {
                confirmButton: 'btn btn-success btn-fill'
                }
            });
        },
        handleDelete(index, row) {
            //console.log('Row: ' + index);
            swal.fire({
                title: 'Are you sure want to delete this?',
                text: `You won't be able to revert this!`,
                icon: '',
                showCancelButton: true,
                customClass: {
                confirmButton: 'btn btn-fill mr-3',
                cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: 'Yes, delete it!',
                buttonsStyling: false
        }).then(result => {
                if (result.value) {
                    /** REMOVE ORGANIZATION */
                    
                        this.$store.dispatch('RemoveClient', {
                            companyID: row.company_id,
                            userID: row.id,
                        }).then(response => {
                            //console.log(response)
                            this.deleteRow(row);
                            swal.fire({
                                title: 'Deleted!',
                                text: `You deleted ${row.name}`,
                                icon: 'success',
                                confirmButtonClass: 'btn btn-default btn-fill',
                                buttonsStyling: false
                            });
                        },error => {
                            
                        });
                        
                    /** REMOVE ORGANIZATION */    
                }
        });
        },
        deleteRow(row) {
        let indexToDelete = this.tableData.findIndex(
            tableRow => tableRow.id === row.id
        );
        if (indexToDelete >= 0) {
            this.tableData.splice(indexToDelete, 1);
        }
        },
        cssCheckBoxRole(){
            const userData = this.$store.getters.userData

            if(userData.systemuser || !this.$global.stripeaccountconnected){
                return 'checkbox__role'
            } else {
                return ''
            }
        },
        onExpandClick(row){
            this.rowClicked(row);
        }
    },

    mounted() {
        this.userData = this.$store.getters.userData;
        this.companyID = this.userData.company_id;
        this.GetAdminList();
        // $('.phonenum input').usPhoneFormat({
        //     format: 'xxx-xxx-xxxx',
        // });
    },

    watch: {
        /**
         * Searches through the table data by a given query.
         * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
         * @param value of the query
         */
        // searchQuery(value) {
        //     let result = this.tableData;
        //     var temp = Array();
        //     if (value !== '') {
        //         result = this.fuseSearch.search(this.searchQuery);
        //         for(let i=0;i<result.length;i++) {
        //             temp.push(result[i].item);
        //             //console.log(result[i].item);
        //         }

        //         if (result.length == 0) {
        //             if (this.tableData.length > 0) {
        //                 this.tableDataOri = [];
        //                 this.tableDataOri = this.tableData;
        //             }
        //             this.tableData = [];
        //         }
                
        //     }else{
        //         this.tableData = this.tableDataOri;
        //     }
        //     this.searchedData = temp;
        // }
    },
    
}
</script>
<style>
.clickable-rows td {
    cursor: pointer;
}
/* .clickable-rows .el-table, .el-table__expanded-cell {
    background-color:#1e1e2f;
} */
.clickable-rows tr .el-table__expanded-cell {
    cursor: default;
}

.el-table_1_column_4 {
    text-align: center !important;
}

.input__administrator__list .el-input__inner {
    padding-left: 30px;
    padding-right: 30px;
}

.el-table table .form-check label .form-check-sign::before {
    top: 0px;
    left: 0px;
}

.checkbox__role > label > span::after {
    top: 0 !important;
    left: 0 !important;
}

.divider-hr {
    border-top: 1px solid var(--text-primary-color) !important;
}

.container__filter__administrator__list {
    float: right;
    margin-bottom: 16px;
}

.button__add__administrator__list {
    margin: 0px;
}

@media (max-width: 575.98px) {
    .container__filter__administrator__list {
        float: none;
    }
    .button__add__administrator__list{
        width: 100%;
        margin-top: 8px;
    }
}
</style>