<template>
    <div>
        <ValidationObserver ref="form">
            <form class="row" id="credit-card">
                <div class="col-sm-12">
                    <!-- <h5 class="info-text">Your Billing Information</h5> -->
                    <!-- <h5 v-if="this.emmplatform">Terms and conditions apply. <br/>60 days no minimum spend, then 12 month plan of minimum $149/month spend.</h5> -->
                    <label>Card Number</label>
                </div>

                <div id="card-element" class="col-sm-12 col-md-12 col-lg-12 card-border">&nbsp;</div> <div class="pt-2 pl-2" v-if="cardfailetoload" style="font-size:0.80143rem"><i class="fas fa-sync-alt pr-2" @click="refreshCCfield();" style="cursor:pointer"></i>Failed to load the Credit Card field. Please <a href="javascript:void(0);" @click="refreshCCfield();" style="font-weight:bold">click here</a> to refresh and try again.</div>
                <div id="carderror" class="col-sm-12 col-md-12 col-lg-12 pt-2 hide" style="color:red !important;"><small>&nbsp;</small></div>

                <div class="col-sm-12 col-md-12 col-lg-12 pt-3">
                <ValidationProvider
                    name="Card Holder Name"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                >
                <base-input
                    v-model="cardholdername"
                    label="Card Holder Name"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
                </ValidationProvider>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                <ValidationProvider
                    name="billingAddress"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                >
                <base-input
                    v-model="billingaddress"
                    label="Billing Address"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
                </ValidationProvider>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                    <div class="country-region-select-wrapper">
                        <span style="color: #525f7f;font-size: 14px;" class="country-label">Country *</span>
                        <country-select style="color: #525f7f;border-color: #cad1d7;" class="country-region-select"  v-model="selects.country" :country="selects.country" topCountry="US" />
                    </div> 
                </div>
                
                <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                <div v-if="selects.country" class="country-region-select-wrapper">
                    <span style="color: #525f7f;font-size: 14px;" class="country-label">{{ selects.country === "CA" ? "Province *" : "State *" }}</span>
                    <region-select style="color: #525f7f;border-color: #cad1d7;" class="country-region-select" v-model="selects.state" :country="selects.country" :region="selects.state"  />
                </div>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6 pt-2">
                    <ValidationProvider
                    name="City"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                    
                    >
                    <base-input
                        v-model="city"
                        label="City"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    </base-input>
                    </ValidationProvider>
                </div>
                
                <div class="col-sm-6 col-md-6 col-lg-6 pt-2">
                    <ValidationProvider
                        name="zipcode"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                        
                        >
                        <base-input
                            v-model="zipcode"
                            label="Zip"
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                            >
                        </base-input>
                    </ValidationProvider>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-12 pt-2">
                    <ValidationProvider
                        name="amount"
                        rules="required"
                        v-slot="{ passed, failed, errors }"
                        
                        >
                        <base-input
                            v-model="amount"
                            label="Amount"
                            :error="errors[0]"
                            :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                            type="text"
                            placeholder="0"
                            class=""
                            @input="validateInput"
                            @blur="formatAmount"
                            @focus="unformatAmount"
                            @copy.prevent @cut.prevent @paste.prevent
                            >
                        </base-input>
                    </ValidationProvider>
                </div>

                <div style="width: 100%; height: 1px; background-color: #ccc; margin: 16px 0;"></div>
                <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="container__button__credit__card">
                        <base-button @click="onSubmit" :disabled="isLoadingSave" style="height: 40px;" class="button__save__credit__card">
                            <i v-if="isLoadingSave" class="el-icon-loading" style="margin-right: 4px; font-size: 18px;"></i>
                            Save
                        </base-button>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
var _elements;
var _cardElement;
var _stripe;
var _tokenid = '';
var _sppubkey = '';
var _this;

export default {
    props: {
        onFieldsValue: Function,
        isLoading: Boolean,
        isClearCreditCard: Boolean,
    },
    data() {
        return {
            userData: {},
            agencyManualBill: false,
            emmplatform: false,
            cardfailetoload: false,
            cardholdername: '',
            billingaddress: '',
            showErrorMessage: false,
            agreeTermDisable: false,
            agreeTermBilling: false,
            agreeTermStatBilling: false,
            selects: {
                country: '',
                state: '',
            },
            city: '',
            zipcode: '',
            amount: '',
            modals: {
                errorbilling: false,
            },
            isLoadingSave: this.isLoading,
        }
    },
    methods: {
        refreshCCfield() {
            if (this.$global.sppubkey != "") {
                _sppubkey = this.$global.sppubkey;
                this.initstripelib();
            }else{
                this.$store.dispatch('getGeneralSetting', {
                    companyID: this.userData.company_root_id,
                    settingname: 'rootstripe',
                }).then(response => {
                    _sppubkey = response.data;
                    this.initstripelib();
                },error => {
                    this.$notify({
                        type: 'primary',
                        message: 'Please try to refresh the page',
                        icon: 'fas fa-bug'
                    })
                    this.cardfailetoload = true;    
                });
            }
        },
        agreedtermofbilling() {
            this.agreeTerm = true;
            this.agreeTermDisable = false;
            this.modals.termofbilling = false;
        },
        termscrolltop() {
            this.$refs.termsandcond.scrollTop = 0;
        },
        onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
            if (Math.ceil(scrollTop) + (clientHeight + 20) >= scrollHeight) {
                this.agreeTermDisable = false;
            }
        },
        onIframeLoad(){
            let iframe = this.$refs.iframeRef;
            try {
                let iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

                if(iframeDoc){
                    const style = iframeDoc.createElement('style');
                    style.innerHTML = '.full-page > .content { padding-top: 0 !important; padding-bottom: 40px !important; }';
                    iframeDoc.head.appendChild(style);
                }

                iframeDoc.addEventListener("scroll", () => {
                    let scrollHeight = iframeDoc.documentElement.scrollHeight;
                    let scrollTop = iframeDoc.documentElement.scrollTop;
                    let clientHeight = iframeDoc.documentElement.clientHeight;

                    if (scrollTop + clientHeight >= scrollHeight - 10) {
                        this.agreeTermDisable = false;
                    }
                });
            } catch (error) {
                console.warn("cross-origin restriction.");
            }
        },
        initstripelib() {
            var chkload = this.$global.stripescriptload;
            if (chkload === false) {
                this.$global.initStripeScriptLoad(true);
                $.getScript( "https://js.stripe.com/v3/" )
                .done(function( script, textStatus ) {
                    initcreditcard();
                })
                .fail(function( jqxhr, settings, exception ) {
                });
            }else{
                initcreditcard();
            }
        },
        showErrorModal() {
            this.modals.errorbilling = true;
        },
        createStripeCustomer() {
            this.isLoadingSave = true;
            var _this = this;
            return new Promise((resolve, reject) => {
                _stripe.createToken(_cardElement).then(function(result) {
                    if(result.error) {
                        _this.isLoadingSave = false;
                        console.log(result.error.message);
                        $('#carderror').html('<small style="color:red !important;"><sup>*</sup>' + result.error.message + '</small>');
                        $('.card-border').addClass('error-border');
                        $('#carderror').show();
                    }else{
                        $('#carderror').hide();
                        $('.card-border').removeClass('error-border');
                        _tokenid = result.token.id;
                        const payload = {
                            tokenid: _tokenid,
                            cardholder: _this.cardholdername,
                            address: _this.billingaddress,
                            city: _this.city,
                            state: _this.selects.state,
                            country: _this.selects.country,
                            zip: _this.zipcode,
                            amount: parseFloat(_this.amount),
                        }

                        _this.onFieldsValue(payload)
                    }
                });
            });
        },
        onSubmit(){
            this.$refs.form.validate().then(res => {
                if (!res) {
                    return false;
                } else {
                    if(this.selects.country == ''){
                        this.$notify({
                            type: 'primary',
                            message: 'Please select country',
                            icon: 'fas fa-bug'
                        })

                        return;
                    }

                    if(this.selects.state == ''){
                        this.$notify({
                            type: 'primary',
                            message: 'Please select state',
                            icon: 'fas fa-bug'
                        })

                        return;
                    }

                    if(parseFloat(this.amount) < 0.5){
                        this.$notify({
                            type: 'danger',
                            message: 'Minimum charge is $0.50',
                            icon: 'fas fa-dollar'
                        })

                        return;
                    }

                    this.createStripeCustomer();
                }
            })
        },
        validateInput() {
            this.amount = this.amount.replace(/[^0-9,\.]/g, "");
        },
        formatAmount() {
            if (!this.amount) return;
            this.amount = new Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(parseFloat(this.amount.replace(/,/g, "")));
        },
        unformatAmount() {
            this.amount = this.amount.replace(/,/g, "");
        }
    },
    mounted() {
        _sppubkey = this.$global.sppubkey;
        _this = this;
        this.agreeTermDisable = true;
        this.cardfailetoload = false;

        if (typeof(_sppubkey) == "undefined" || _sppubkey == "") {
            this.cardfailetoload = true;
        } else {
            this.initstripelib();
        }
    },
    watch: {
        isLoading: {
            handler(newValue){
                this.isLoadingSave = newValue;
            },
            deep: true,
            immediate: true,
        },
        isClearCreditCard: {
            handler(newValue){
                if(newValue){
                    _cardElement.clear();
                    this.cardfailetoload = false;
                    this.cardholdername = '';
                    this.billingaddress = '';
                    this.selects.country = '';
                    this.selects.state = '';
                    this.city = '';
                    this.zipcode = '';
                    this.amount = '';
                    this.$refs.form.reset();
                }
            },
            deep: true,
            immediate: true,
        },
    }
}

function initcreditcard() {
  if (!$('#card-element').length) {
    return
  }

	_stripe = Stripe(_sppubkey);
	/** ATTACHED CARD ELEMENTS */
   _elements = _stripe.elements();
    _cardElement = _elements.create('card', {
    hidePostalCode: true,   
    style: {
        base: {
               color: 'rgba(82, 95, 127, 0.8)',
                fontSize: '16px',
                fontFamily: '"Open Sans", sans-serif',
                fontSmoothing: 'antialiased',
                '::placeholder': {
                  color: 'rgba(82, 95, 127, 0.3)',
                },
            },
            invalid: {
                color: '#e5424d',
                ':focus': {
                  color: 'rgba(82, 95, 127, 0.3)',
                },
            },
        },
    });
    _this.cardfailetoload = false;

    if (typeof(_cardElement) == "undefined") {
      _this.cardfailetoload = true;
    }
    //var cardElement = elements.create('card');
    _cardElement.mount('#card-element');
    /** ATTACHED CARD ELEMENTS */

    /** CARD ELEMENT VALIDATION */
    _cardElement.on('change', function(event) {
        if (event.error) {
          console.log('Card Error :' + event.error.message);
          $('#carderror').html('<small style="color:red !important;"><sup>*</sup>' + event.error.message + '</small>');
          $('#carderror').show();
          $('.card-border').addClass('error-border');
          //this.cardError = true;

          //_CardValidate = false;
        } else {
          $('#carderror').hide();
          $('.card-border').removeClass('error-border');
          //_CardValidate = true;
        }
    });
}
</script>

<style>
.modal-errorbilling {
  max-width: 520px;
  margin: 0 auto;
}
#modaltermofbilling .termofbilling {
  overflow-y: scroll;
  height: 300px;
  width: 100%;
  padding: 10px;
}

.ccmonth {
  width: 50% !important;
}
.ccyear {
  width: 50% !important;
  padding-left: 10px;
}
#credit-card .card-border {
  max-width: 97.5%;
  margin-left: 15px;
  line-height: 1.5;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #cad1d7;
  border-radius:8px;
  padding-top:8px;
  height:39px;
  box-sizing: border-box;
  transform: translateZ(0);
  -webkit-user-select:text;
}

.termsx {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 300px;
  width: 100%;
  border: 1px solid #525f7f;
  padding: 10px;
  background-color: #FFF;
}

.termsx p, .termsx span {
  color: #525f7f !important;
}

.termsx a,.termsx a:hover {
  color: #942434 !important;
}

#style-2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	/*border-radius: 10px;*/
	background-color: gray;
  opacity: 0.5;
}

#style-2::-webkit-scrollbar
{
	width: 12px;
	background-color: #525f7f;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #525f7f;
}

.termsx p.bold {
    font-weight: bold;
}

.container__button__credit__card {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-bottom: 24px;
}

@media (max-width: 767px) {
    .button__save__credit__card {
        width: 100%;
        margin-top: 8px;
    }
}

</style>